<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.el-form-item {
		margin-bottom: 0px;
	}
	
</style>

<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>资讯管理</el-breadcrumb-item>
			  <el-breadcrumb-item>资讯列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top: 10px; background-color: white; padding: 10px; margin: 10px; border-radius: 5px;">
			<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-bottom: 10px;">

				<el-form :inline="true" class="demo-form-inline">
					
					
					<el-form-item label="">
						<el-input v-model="keyword" placeholder="资讯标题"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="onSubmit">搜索查询</el-button>
						
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" ref="myTable" border  style="width: 100%" 
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)" >
				
				
				
				<el-table-column prop="aid" label="编号" width="80"  align="center">
				</el-table-column>
				
				<el-table-column prop="active_img" label="资讯封面" align="center" width="400">
					<template slot-scope="scope">
						<img :src="scope.row.cover_url" style="height: 120px; width: 240px;" />
					</template>
				</el-table-column>
				
				<el-table-column prop="title" label="资讯标题" width="350"  align="center">
				</el-table-column>
				
				<el-table-column prop="status" label="显示状态"  align="center"  width="150">
					<template slot-scope="scope">
						<el-button @click="status_func(scope.row)" v-if="scope.row.status==0" type="" circle>否</el-button>
						<el-button @click="status_func(scope.row)" v-if="scope.row.status==1" type="primary" circle>是</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="status" label="置顶"  align="center"  width="150">
					<template slot-scope="scope">
						<el-button @click="top_func(scope.row)" v-if="scope.row.is_top==0" type="" circle>否</el-button>
						<el-button @click="top_func(scope.row)" v-if="scope.row.is_top==1" type="primary" circle>是</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="add_time" label="发布时间" align="center" width="250">
				</el-table-column>
				
				<el-table-column fixed="right" label="操作" width="280" align="center">
					<template slot-scope="scope">
						<el-button type="primary" @click="edit(scope.row.aid)" icon="el-icon-edit" size="mini" ></el-button>
						<el-button type="warning" @click="copy(scope.row)" size="mini" >复制链接</el-button>
						<el-button type="danger" @click="del(scope.row.aid)" icon="el-icon-delete" size="mini"></el-button>
					</template>
				</el-table-column>
				
			</el-table>
			<el-pagination background layout="prev, pager, next" :key="tableKey" :page-size="limit" @current-change="getData" :total="total" style="margin-top: 20px;"></el-pagination>
		</div>
		
		<el-dialog v-if="actionOrderView" title="编辑资讯" :visible.sync="actionOrderView" width="900px" @close="actionOrderViewClose">
			<editArticle :aid="aid"></editArticle>
		</el-dialog>
		
		
		<el-dialog title="复制小程序链接" :visible.sync="copyWinViews" width="700px" @close="copyWinViewsClose">
			<el-input v-model="copy_url"></el-input>
		</el-dialog>
	</div>
</template>

<script>
	
	import editArticle from './editArticle.vue';
	
	export default {
		name:'List',
		components:{
			editArticle
		},
		data(){
			return {
				copy_url:'',
				/* 操作订单控制参数 */
				copyWinViews:false,
				actionOrderView:false,
				actionOrderTitle:'订单操作',
				/* 搜索参数 */
				payway:'',
				store_id:'',
				keyword:'',
				/* 表格参数 */
				tableKey:0,
				loading:false,
				limit:10,
				total:0,
				tableData: [],
				aid:0
			}
		},
		created() {
			this.getData();
		},
		methods:{
			del(_id){
				let that = this;
				this.$confirm('是否确认删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					that.$api.post("/Article/delArticle", {aid:_id}).then(res=>{
						that.getData();
						that.$message({
							type: 'success',
							message: '删除成功!'
						});
					}).catch(res=>{
					});
				}).catch(() => {
				});
			},
			copyWinViewsClose(){
				this.copy_url = '';
			},
			copy(_row){
				let _url = 'pages/article/index?aid=' + _row.aid;
				this.copy_url = _url;
				this.copyWinViews = true;
			},
			edit(_aid){
				this.actionOrderView = true;
				this.aid = _aid;
			},
			actionOrderViewClose(){
				this.aid = 0;
			},
			deleteOrder(row, index){
				let that = this;
				this.$confirm('是否确认删除此订单?', '提示', {
					confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
				}).then(() => {
					that.deleteOrderAction(row, index);
				}).catch(() => {
				});	
			},
			deleteOrderAction(row, index){
				const loading = this.$loading({
						lock: true,
						text: '结束中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				that.$api.post("/Order/deleteOrder", {order_id:row.order_id}).then(res=>{
					setTimeout(()=>{loading.close()}, 200);
					if(res.code == 1)
					{
						that.tableData.splice(index, 1);
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
			actionOrderFunc(e){
				this.actionOrderInfo = e;
				this.actionOrderTitle = e.room_name + "       " + e.store_name + " ";
				this.actionOrderView = true;
			},
			paywayChange(e){
				this.payway = e;
				this.getData();
			},
			storeChange(e){
				this.store_id = e;
				this.getData(1);
			},
			refresh(){
				this.getData();
			},
			getData(page=1){
				let that = this;
				that.loading = true;
				that.$api.post("/Article/articleList", {page:page, keyword:that.keyword}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			
			onSubmit(){
				this.getData();
			},
			top_func(row){
				const loading = this.$loading({
						lock: true,
						text: '执行中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				let _new_status = 0;
				if(row.is_top == 0)
				{
					_new_status = 1;
				}else{
					_new_status = 0;
				}
				
				that.$api.post("/Article/articleTopChange", {aid:row.aid, is_top:_new_status}).then(res=>{
					setTimeout(()=>{loading.close()}, 100);
					if(res.code == 200)
					{
						that.tableData.forEach((v,k)=>{
							if(v.aid == row.aid)
							{
								that.tableData[k].is_top = _new_status;
							}
						});
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
			status_func(row){
				const loading = this.$loading({
						lock: true,
						text: '执行中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				let _new_status = 0;
				if(row.status == 0)
				{
					_new_status = 1;
				}else{
					_new_status = 0;
				}
				
				that.$api.post("/Article/articleStatusChange", {aid:row.aid, status:_new_status}).then(res=>{
					setTimeout(()=>{loading.close()}, 100);
					if(res.code == 200)
					{
						that.tableData.forEach((v,k)=>{
							if(v.aid == row.aid)
							{
								that.tableData[k].status = _new_status;
							}
						});
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
		}
	}
</script>