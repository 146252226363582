import { render, staticRenderFns } from "./articleList.vue?vue&type=template&id=32e97fbc&scoped=true&"
import script from "./articleList.vue?vue&type=script&lang=js&"
export * from "./articleList.vue?vue&type=script&lang=js&"
import style0 from "./articleList.vue?vue&type=style&index=0&id=32e97fbc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32e97fbc",
  null
  
)

export default component.exports