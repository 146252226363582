<template>
  <el-container class="body">
      <div class="content" style="z-index: 1;">
      	<div class="login-box">
      		<div class="login-left">
      			<div class="logo">翀腾文化</div>
      			<div class="logo-header">后台管理系统</div>
      		</div>
      		<div class="login-right">
				<el-input class="input" v-model="username" placeholder="账号" clearable></el-input>
				<el-input class="input" v-model="password" placeholder="密码" show-password clearable></el-input>
      			<el-checkbox class="input" v-model="remember">记住密码</el-checkbox>
				<el-button class="input" type="primary" @click="login()">立即登录</el-button>
      		</div>
      	</div>
		<div class="copy"><a href="https://easy2.cn" target="_blank">技术支持©易达星辰</a></div>
      </div>
  </el-container>
</template>

<script>
export default {
	data() {
		return {
			username: '',
			password: '',
			remember: false,
		};
	},
	mounted() {
		console.log("mounted");

		this.$nextTick(() => {
			let remember = localStorage.getItem("rememberLogin");
			if(remember == 1)
			{
				this.username = localStorage.getItem("username");
				this.password = localStorage.getItem("password");
				this.remember = true;
			}
		});
	},
	methods: {

		remembers(){
			if(this.remember === true)
			{
				localStorage.setItem("rememberLogin", 1);
				localStorage.setItem("username", this.username);
				localStorage.setItem("password", this.password);
			}else{
				localStorage.setItem("rememberLogin", 0);
				localStorage.removeItem("username");
				localStorage.removeItem("password");
			}
		},
		login() {
			let that = this;
			if(that.username.length < 5 || that.password.length < 5)
			{
				return that.$message({
					 message: '请输入正确的账号密码哟~',
					 type: 'warning',
				});
			}
			
			
			const loading = this.$loading({
					lock: true,
					text: '登录中',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
			that.$api.post("/Login/LoginApi", {username:that.username, password:that.password}).then(res=>{
				if(res.code == 1)
				{
					that.$store.commit("loginSuccess", res.data);
					that.remembers();
					that.$message({
						 message: '登录成功~',
						 type: 'success',
					});
					setTimeout(()=>{
						that.$router.push("/Dashboard");
					}, 1000);
				}else{
					that.$message({
						 message: res.msg,
						 type: 'warning',
					});
				}
				loading.close();
			}).catch(err=>{
				loading.close();
			});
		},
	},
};
</script>

<style lang="scss" scoped>
	
	.copy {
		width: 200px;
		height: 80px;
		line-height: 80px;
		text-align: center;
	}
	.copy a {
		text-decoration: none;
		color: #999999;
		font-size: 14px;
	}
	.body {
		margin:0 ;
		background-image: url(/src/assets/bg3.jpg);
		background-color: #000000;
		width: 100%;
		background-size: cover;
		background-position: 50%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		height: 100vh;
		overflow: auto;
	}

	.content {
		width: 100%; height: 100vh; display: flex; flex-direction: column; align-items: center;
	}
	.login-box {
		width: 600px; height: 300px; margin-top: 300px; display: flex; flex-direction: row;
		border-radius: 20px;
		box-shadow: 0px 0px 10px #000000;
		overflow: hidden;
		border: solid 1px white;
	}
	.login-left {
		width: 300px;
		opacity: 0.9;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		
	}

	.logo {
		height: 60px;
		line-height: 60px;
		color: white;
		font-size: 28px;
		width: 240px;
		text-align: left;
		text-indent: 20px;
		border-bottom: dashed 1px white;
	}

	.logo-header {
		height: 60px;
		line-height: 60px;
		color: white;
		font-size: 20px;
		color: #ffffff;
		width: 240px;
		text-align: left;
		text-indent: 20px;
	}

	.login-right {
		background-color: rgba(255, 255, 255, 0.1);
		box-shadow: 0px 0px 5px #000000;

		width: 300px;

		align-items: center;
		justify-content: center;
		font-size: 20px;
		color: #999999;
		display: flex;
		flex-direction: column;
	}
	
	.input {
		margin-bottom: 15px;
		width: 200px;
	}
	.btn {
		opacity: 1;
		width: 180px;
		height: 30px;
		border:0;
		border-radius: 6px;
		background-color: #3399ff;
		color: white;
		font-size: 12px;
		box-shadow: 0px 0px 10px #000000;
	}


	.remember_box {
		width: 180px;
		margin-bottom: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.remember {
		text-indent: 5px;
		font-size: 12px;
		color: #333333;
	}

	input[type=checkbox] {
	  width: 18px;
	  height: 18px;
	}
</style>
