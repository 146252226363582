<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.el-form-item {
		margin-bottom: 0px;
	}
	
	.t {
		width: 100px;
		text-align: right;
		margin-right: 10px;
	}
	
	.line {
		display: flex;
		flex-direction: row;
		font-size: 14px;
		line-height: 30px;
	}
	
</style>

<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="getData" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>小程序管理</el-breadcrumb-item>
			  <el-breadcrumb-item>幻灯片设置</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top: 10px; background-color: white; padding: 10px; margin: 10px; border-radius: 5px;">
			<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-bottom: 10px;">
				<el-form :inline="true" class="demo-form-inline">
					<el-form-item>
						<el-button type="primary" @click="upload">上传图片</el-button> <span style="color: #888888; font-size: 12px;">（宽高比2:1 宽度小于900合适）</span>
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" ref="myTable" border  style="width: 100%" 
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)" >

				<el-table-column prop="id" label="编号"  align="center">
				</el-table-column>
				
				<el-table-column prop="img_url" label="幻灯片"  align="center">
					<template slot-scope="scope">
						<img :src="scope.row.img_url" style="height: 120px; width: 240px;" />
					</template>
				</el-table-column>
				
				<el-table-column prop="status" label="显示状态"  align="center">
					<template slot-scope="scope">
						<el-button @click="status_func(scope.row)" v-if="scope.row.status==0" type="" circle>否</el-button>
						<el-button @click="status_func(scope.row)" v-if="scope.row.status==1" type="primary" circle>是</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="status" label="排序"  align="center" width="120">
					<template slot-scope="scope">
						<el-input v-model="scope.row.sorts" placeholder="排序" style="text-align: center;" @change="sortsChange($event, scope.row)"></el-input>
					</template>
				</el-table-column>
				
				<el-table-column fixed="right" label="操作"  align="center">
					<template slot-scope="scope">
						<el-button @click="del(scope.row.id)" type="primary" size="mini">删除</el-button>
					</template>
				</el-table-column>
				
			</el-table>
		</div>
		<el-upload class="avatar-uploader" :action="uploadUrl" name="file" :show-file-list="false" :on-success="uploadSuccess"></el-upload>
	</div>
</template>

<script>

	
	export default {
		name:'List',
		components:{
	
		},
		data(){
			return {
				uploadUrl:'https://chongteng.easy2.cn/Api/Upload/uploadPlayerImage',
				loading:false,
				tableData: []
			}
		},
		created() {
			this.getData();
		},
		methods:{
			sortsChange(e, v){
				this.$api.post("/Config/changePlayerSorts", {id:v.id, sorts:e}).then(res=>{
					
				});
				console.log(e, v);
			},
			del(_id){
				let that = this;
				this.$confirm('是否确认删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					that.$api.post("/Config/delPlayer", {id:_id}).then(res=>{
						that.getData();
						that.$message({
							type: 'success',
							message: '删除成功!'
						});
					}).catch(res=>{
					});
				}).catch(() => {
				});
			},
			upload(){
				document.querySelector(".avatar-uploader input").click();
			},
			uploadSuccess(res) {
				this.$message({ message: '上传成功', type: 'success' });
				this.getData();
			},
			getData(){
				let that = this;
				that.loading = true;
				that.$api.post("/Config/getPlayerList", {}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
				}).catch(res=>{
					that.loading = false;
				});
			},
			onSubmit(){
				this.getData();
			},
			status_func(row){
				const loading = this.$loading({
						lock: true,
						text: '执行中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				let _new_status = 0;
				if(row.status == 0)
				{
					_new_status = 1;
				}else{
					_new_status = 0;
				}
				
				that.$api.post("/Config/changePlayerStatus", {id:row.id, status:_new_status}).then(res=>{
					setTimeout(()=>{loading.close()}, 100);
					if(res.code == 200)
					{
						that.tableData.forEach((v,k)=>{
							if(v.id == row.id)
							{
								that.tableData[k].status = _new_status;
							}
						});
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
		}
	}
</script>