<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.el-form-item {
		margin-bottom: 0px;
	}
	
</style>

<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>赛事管理</el-breadcrumb-item>
			  <el-breadcrumb-item>赛事列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top: 10px; background-color: white; padding: 10px; margin: 10px; border-radius: 5px;">
			<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-bottom: 10px;">
				<!-- <div style="height: 40px; line-height: 40px;">用户列表</div> -->
				<el-form :inline="true" class="demo-form-inline">
					
					
					<el-form-item label="">
						<el-input v-model="keyword" placeholder="赛事名称"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="onSubmit">搜索查询</el-button>
						
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" ref="myTable" border  style="width: 100%" 
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)" >
				
				
				
				<el-table-column prop="at_id" label="编号" width="80"  align="center">
				</el-table-column>
				
				<el-table-column prop="active_img" label="赛事封面" align="center" width="200">
					<template slot-scope="scope">
						<img :src="scope.row.active_img" style="height: 80px; width: 160px;" />
					</template>
				</el-table-column>
				
				<el-table-column prop="active_title" label="赛事标题" width="300"  align="center">
				</el-table-column>

				
				<el-table-column prop="active_name" label="子标题" width="140"  align="center">
				</el-table-column>
				
				<el-table-column prop="start_time" label="报名日期" width="120"  align="center">
				</el-table-column>
				
				<el-table-column prop="ended_time" label="截止日期" align="center" width="120">
				</el-table-column>
				
				<el-table-column prop="price" label="组别管理" align="center" width="140">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="group(scope.row.at_id)">组别管理</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="views" label="显示状态"  align="center"  width="100">
					<template slot-scope="scope">
						<el-button @click="views_func(scope.row)" v-if="scope.row.views==0" circle>否</el-button>
						<el-button @click="views_func(scope.row)" v-if="scope.row.views==1" type="primary" circle>是</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="status" label="报名状态"  align="center"  width="100">
					<template slot-scope="scope">
						<el-button @click="status_func(scope.row)" v-if="scope.row.status==0" circle>否</el-button>
						<el-button @click="status_func(scope.row)" v-if="scope.row.status==1" type="primary" circle>进</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="is_top" label="置顶"  align="center"  width="100">
					<template slot-scope="scope">
						<el-button @click="top_func(scope.row)" v-if="scope.row.is_top==0" circle>否</el-button>
						<el-button @click="top_func(scope.row)" v-if="scope.row.is_top==1" type="danger" circle>是</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="add_time" label="创建时间" align="center" width="180">
				</el-table-column>
				
				<el-table-column fixed="right" label="操作" width="300" align="center">
					<template slot-scope="scope">

						<el-button type="primary" @click="edit(scope.row)" icon="el-icon-edit" size="mini" ></el-button>
						<el-button type="warning" @click="copy(scope.row)" size="mini" >复制链接</el-button>
						<el-button type="danger" @click="del(scope.row.at_id)" icon="el-icon-delete" size="mini"></el-button>
						
					</template>
				</el-table-column>
				
			</el-table>
			<el-pagination background layout="prev, pager, next" :key="tableKey" :page-size="limit" @current-change="getData" :total="total" style="margin-top: 20px;"></el-pagination>
		</div>
		
		<el-dialog title="查看编辑" v-if="editView" :visible.sync="editView" width="900px" @close="editViewClose" center>
			<editActivity :at_id="activityData.at_id"></editActivity>
		</el-dialog>
		
		<el-dialog title="查看报名信息" :visible.sync="exportView" width="700px" @close="exportViewClose">
			查看报名信息
		</el-dialog>
		
		<el-dialog title="复制小程序链接" :visible.sync="copyWinViews" width="700px" @close="copyWinViewsClose">
			<el-input v-model="copy_url"></el-input>
		</el-dialog>
		
		
		<el-dialog title="组别管理" v-if="groupWinViews" :visible.sync="groupWinViews" width="700px" @close="groupWinViewsClose">
			<div style="display: flex; flex-direction: row; ">
				<el-input placeholder="组别名称" v-model="add.name" style="width: 180px; margin-right: 10px;" />
				<el-input  placeholder="报名费用" v-model="add.fee" style="width: 90px; margin-right: 10px;" />
				<el-button type="primary" @click="addGroup()">添加新组别</el-button>
			</div>
			<div style="display: flex; flex-direction: row; height: 60px; line-height: 60px;">
				<div style="width:100px; text-align: center;">编号</div>
				<div style="width:200px; text-align: center;">组别名称</div>
				<div style="width:100px; text-align: center;">报名费用</div>
				<div style="width:200px; text-align: center;">操作</div>
			</div>
			<div v-for="(item,index) in group_list"  style="border-bottom: solid 1px #dcdcdc; display: flex; flex-direction: row; height: 60px; line-height: 60px; align-items: center;">
				<div style="width:100px; text-align: center;">{{item.cid}}</div>
				<div style="width:200px; text-align: center;"><el-input v-model="item.name" style="width: 180px;" /></div>
				<div style="width:100px; text-align: center;"><el-input v-model="item.fee" style="width: 90px;" /></div>
				<div style="width:200px; text-align: center;">
					<el-button type="primary" size="mini" @click="editGroup(item.cid)">修改</el-button>
					<el-button type="danger" size="mini" @click="delGroup(item.cid)">删除</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import editActivity from './editActivity.vue';

	export default {
		name:'List',
		components:{
			editActivity
		},
		data(){
			return {
				add:{name:'',fee:''},
				group_id:0,
				groupWinViews:false,
				group_list:[],
				copy_url:'',
				/* 操作订单控制参数 */
				copyWinViews:false,
				exportView:false,
				editView:false,
				activityData:{},
				/* 搜索参数 */
				keyword:'',
				/* 表格参数 */
				tableKey:0,
				loading:false,
				limit:10,
				total:0,
				tableData: []
			}
		},
		created() {
			this.getData();
		},
		methods:{
			addGroup(){
				console.log("add group ", this.group_id, this.add);
				let that = this;
				const loading = this.$loading({
						lock: true,
						text: '添加中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				that.add.at_id = that.group_id;
				that.$api.post("/Activity/addActivityGroup", that.add).then(res=>{
					if(res.code == 1)
					{
						that.getGroupList(that.group_id);
						that.add = {name:'',fee:''};
						that.$message({
							type: 'success',
							message: '添加成功!'
						});
					}else{
						that.$message({
							type: 'success',
							message: res.msg
						});
					}
					loading.close()
				}).catch(res=>{
					loading.close();
				});
				
				
			},
			editGroup(_cid){
				let _group = {};
				this.group_list.forEach((k,v)=>{
					if(k.cid == _cid)
					{
						_group = k;
					}
				})
				console.log("editGroup", _cid, _group);
				let that = this;
				const loading = this.$loading({
						lock: true,
						text: '修改中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				that.$api.post("/Activity/editActivityGroup", _group).then(res=>{
					if(res.code == 1)
					{
						that.$message({
							type: 'success',
							message: '修改成功!'
						});
					}else{
						that.$message({
							type: 'success',
							message: res.msg
						});
					}
					loading.close()
				}).catch(res=>{
					loading.close();
				});
				
				
			},
			delGroup(_cid){
				let that = this;
				this.$confirm('是否确认删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					that.$api.post("/Activity/delActivityCate", {cid:_cid}).then(res=>{
						if(res.code == 1)
						{
							that.getGroupList(that.group_id);
							that.$message({
								type: 'success',
								message: '删除成功!'
							});
							
						}else{
							that.$message({
								type: 'success',
								message: res.msg
							});
						}
					}).catch(res=>{
					});
				}).catch(() => {
				});
			},
			groupWinViewsClose(){
				this.group_id = 0;
				this.group_list = [];
			},
			getGroupList(_at_id){
				let that = this;
				const loading = this.$loading({
						lock: true,
						text: '获取数据中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				that.$api.post("/Activity/getActivityGroup", {at_id:_at_id}).then(res=>{
					if(res.code == 1)
					{
						that.group_list = res.data.list;
					}else{
						that.group_list = [];
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close()
				}).catch(res=>{
					loading.close();
				});
			},
			group(_at_id){
				this.groupWinViews = true;
				console.log("edit group", _at_id);
				this.group_id = _at_id;
				this.getGroupList(_at_id);
			},
			del(_id){
				let that = this;
				this.$confirm('是否确认删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					that.$api.post("/Activity/delActivity", {at_id:_id}).then(res=>{
						that.getData();
						that.$message({
							type: 'success',
							message: '删除成功!'
						});
					}).catch(res=>{
					});
				}).catch(() => {
				});
			},
			copyWinViewsClose(){
				this.copy_url = '';
			},
			copy(_row){
				let _url = 'pages/activity/index?at_id=' + _row.at_id;
				this.copy_url = _url;
				this.copyWinViews = true;
			},
			viewExport(){
				this.exportView = true;
			},
			edit(data){
				this.activityData = data;
				console.log(data);
				console.log(this.activityData.at_id)
				this.editView = true;
			},
			editViewClose(){
			},
			exportViewClose(){
				
			},
			top_func(row){
				const loading = this.$loading({
						lock: true,
						text: '执行中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				let _new_status = 0;
				if(row.is_top == 0)
				{
					_new_status = 1;
				}else{
					_new_status = 0;
				}
				
				that.$api.post("/Activity/activityTopChange", {at_id:row.at_id, is_top:_new_status}).then(res=>{
					setTimeout(()=>{loading.close()}, 100);
					if(res.code == 200)
					{
						that.tableData.forEach((v,k)=>{
							if(v.aid == row.aid)
							{
								that.tableData[k].is_top = _new_status;
							}
						});
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
			views_func(row){
				const loading = this.$loading({
						lock: true,
						text: '执行中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				let _new_status = 0;
				if(row.views == 0)
				{
					_new_status = 1;
				}else{
					_new_status = 0;
				}
				
				that.$api.post("/Activity/activityViewsChange", {at_id:row.at_id, views:_new_status}).then(res=>{
					setTimeout(()=>{loading.close()}, 100);
					if(res.code == 200)
					{
						that.tableData.forEach((v,k)=>{
							if(v.at_id == row.at_id)
							{
								that.tableData[k].views = _new_status;
							}
						});
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
			status_func(row){
				const loading = this.$loading({
						lock: true,
						text: '执行中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				let _new_status = 0;
				if(row.status == 0)
				{
					_new_status = 1;
				}else{
					_new_status = 0;
				}
				
				that.$api.post("/Activity/activityStatusChange", {at_id:row.at_id, status:_new_status}).then(res=>{
					setTimeout(()=>{loading.close()}, 100);
					if(res.code == 200)
					{
						that.tableData.forEach((v,k)=>{
							if(v.at_id == row.at_id)
							{
								that.tableData[k].status = _new_status;
							}
						});
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
			refresh(){
				this.getData();
			},
			getData(page=1){
				let that = this;
				that.loading = true;
				that.$api.post("/Activity/activityList", {page:page, keyword:that.keyword}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			onSubmit(){
				this.getData();
			}
		}
	}
</script>