<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .up_img {
	  width: 178px; height: 178px; border-radius: 5px; margin-right: 5px;
	  border:dashed 1px #dddddd;
  }
  
  .up_img:hover {
	  cursor: move;
	  border:dashed 1px #409EFF;
	  width: 178px;
	  height: 178px;
  }
  
  .up_img_close {
	  color:black; width:20px; height: 20px; border-radius: 10px; background-color: #CCCCCC; text-align: center; line-height: 20px; position: absolute; right: 15px; top: 10px;
  }
  
  .up_img_close:hover {
	  background-color: #409EFF;
	  color: white;
	  cursor: pointer;
  }
</style>
<template>
	<div id="body">
		<div style=" box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white;  display: flex;  flex-direction: row; justify-content: space-between; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<div style="display: flex; flex-direction: row; align-items: center; ">
				<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
				<el-breadcrumb separator-class="el-icon-arrow-right">
				  <el-breadcrumb-item>首页</el-breadcrumb-item>
				  <el-breadcrumb-item>小程序管理</el-breadcrumb-item>
				  <el-breadcrumb-item>小程序设置</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="body_content" >
			<div style="background-color: white; padding: 10px; border-radius: 10px; ">
				<el-form ref="form" :model="form" label-width="180px" style="width: 700px;" >
					<el-form-item label="客服手机">
						<el-input v-model="form.phone"></el-input>
					</el-form-item>
					<el-form-item label="Appid">
						<el-input v-model="form.appid"></el-input>
					</el-form-item>
					<el-form-item label="Secret">
						<el-input v-model="form.secret"></el-input>
					</el-form-item>
					<el-form-item label="apiv3_key">
						<el-input v-model="form.apiv3_key"></el-input>
					</el-form-item>
					<el-form-item label="merchant_id">
						<el-input v-model="form.merchant_id"></el-input>
					</el-form-item>
					<el-form-item label="cert_serial_no">
						<el-input v-model="form.cert_serial_no"></el-input>
					</el-form-item>
					<el-form-item label="AcsToken">
						<el-input v-model="form.access_time" disabled></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="submit">保存设置</el-button><el-button type="warning" @click="getNewToken">刷新token</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name:'activityAdd',
		data(){
			return {
				form:{
					appid:'',
					secret:'',
					access_time:'',
				}
			}
		},
		mounted() {
			this.getData();
		},
		methods:{
			getNewToken(){
				const loading = this.$loading({
						lock: true,
						text: '刷新中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				that.$api.post("/Config/refreshMiniProgramAccessToken", {}).then(res=>{
					setTimeout(()=>{loading.close()}, 200);
					if(res.code == 200)
					{
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
			getData(){
				const loading = this.$loading({
						lock: true,
						text: '数据获取中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				that.$api.post("/Config/getMiniProgramConfig", {}).then(res=>{
					setTimeout(()=>{loading.close()}, 200);
					if(res.code == 200)
					{
						that.form = res.data;
						console.log(that.form, res.data);
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
			refresh(){
				this.getData();
			},
			submit(){
				console.log("submit data", this.form);
				let that = this;
				if(that.form.appid == ""){
					return that.$message({ message: '请填写appid', type: 'warning' });
				}
				if(that.form.secret == ""){
					return that.$message({ message: '请填写secret', type: 'warning' });
				}


				let loading = this.$loading({
						lock: true,
						text: '提交中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				
				that.$api.post("/Config/setMiniProgramConfig", that.form ).then(res=>{
					if(res.code == 200 )
					{
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
						this.refresh();
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close()
				}).catch(err=>{
					loading.close()
				});
			}
		}
	}
</script>

<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	.body_content {
		padding: 10px;
	}

</style>