<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; justify-content: space-between; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<div style="display: flex; flex-direction: row; align-items: center;">
				<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
				
				<el-breadcrumb separator-class="el-icon-arrow-right">
				  <el-breadcrumb-item>首页</el-breadcrumb-item>
				  <el-breadcrumb-item>数据看板</el-breadcrumb-item>
				</el-breadcrumb>
			
			</div>

		</div>
		<div class="row">
			<div class="data-box">
				<div class="data-icon cl_green"><i class="el-icon-s-order"></i></div>
				<div class="data-line">
					<div class="ckey">{{static.today_order}}</div>
					<div class="cvalue">今日报名</div>
				</div>
			</div>
			
			<div class="data-box">
				<div class="data-icon cl_green"><i class="el-icon-s-order"></i></div>
				<div class="data-line">
					<div class="ckey">{{static.yesterday_order}}</div>
					<div class="cvalue">昨日报名</div>
				</div>
			</div>
			
			
			<div class="data-box">
				<div class="data-icon cl_red"><i class="el-icon-user-solid"></i></div>
				<div class="data-line">
					<div class="ckey">{{static.today_price}}</div>
					<div class="cvalue">今日金额</div>
				</div>
			</div>
			<div class="data-box">
				<div class="data-icon cl_red"><i class="el-icon-user-solid"></i></div>
				<div class="data-line">
					<div class="ckey">{{static.yesterday_price}}</div>
					<div class="cvalue">昨日金额</div>
				</div>
			</div>
			
			<div class="data-box">
				<div class="data-icon cl_orange"><i class="el-icon-s-order"></i></div>
				<div class="data-line">
					<div class="ckey">{{static.month_order}}</div>
					<div class="cvalue">本月报名</div>
				</div>
			</div>
			
			<div class="data-box">
				<div class="data-icon cl_orange"><i class="el-icon-s-order"></i></div>
				<div class="data-line">
					<div class="ckey">{{static.lastmonth_order}}</div>
					<div class="cvalue">上月报名</div>
				</div>
			</div>
			
			<div class="data-box">
				<div class="data-icon cl_blue"><i class="el-icon-s-order"></i></div>
				<div class="data-line">
					<div class="ckey">{{static.month_price}}</div>
					<div class="cvalue">本月金额</div>
				</div>
			</div>
			
			<div class="data-box">
				<div class="data-icon cl_blue"><i class="el-icon-s-order"></i></div>
				<div class="data-line">
					<div class="ckey">{{static.lastmonth_price}}</div>
					<div class="cvalue">上月金额</div>
				</div>
			</div>
			
			<div class="data-box">
				<div class="data-icon cl_grey"><i class="el-icon-s-order"></i></div>
				<div class="data-line">
					<div class="ckey">{{static.user_total}}</div>
					<div class="cvalue">总会员数</div>
				</div>
			</div>
			
			<div class="data-box">
				<div class="data-icon cl_grey"><i class="el-icon-s-order"></i></div>
				<div class="data-line">
					<div class="ckey">{{static.user_add}}</div>
					<div class="cvalue">今日新增</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>

	export default {
		name:'Dashboard',
		data(){
			return {
				static:{
					today_order:0,
					today_price:"0.00",
					yesterday_order:0,
					yesterday_price:"0.00",
					month_order:0,
					month_price:"0.00",
					lastmonth_order:0,
					lastmonth_price:"0.00",
					user_total:0,
					user_add:0
				},
				store_id:'',
				chart1:null,
				chart2:null,
				chart_json1:{},
				chart_json2:{}
			}
		},
		mounted() {
			this.getData();
		},
		methods:{
			storeChange(e){
				this.store_id = e;
				this.getData();
			},
			refresh(){
				console.log("refresh click");
				this.getData();
			},
			getData(){
				const loading = this.$loading({
						lock: true,
						text: '拼命刷新中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				that.$api.post("/Dashboard/getDashBoard", {store_id:that.store_id}).then(res=>{
					setTimeout(()=>{loading.close()}, 200);
					if(res.code == 1)
					{
						that.static = res.data.static;
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					
				}).catch(res=>{
					loading.close();
				});
					
			}
		}
	}
</script>


<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.cl_green {
		color: #67C23A;
	}
	.cl_orange {
		color: #E6A23C;
	}
	.cl_red {
		color: #F56C6C;
	}
	.cl_blue {
		color: #409EFF;
	}
	.cl_grey {
		color: #909399;
	}
	
	.row {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		margin: 15px 0 0 0;
	}
	
	.data-box {
		display: flex;
		flex-direction: row;
		background-color: white;
		padding: 20px 20px;
		margin: 5px 5px;
		border-radius: 10px;
		justify-content: space-between;
		width: 16%;
		min-width: 190px;
		box-shadow: 1px 1px 3px #CCCCCC;
	}
	
	.data-icon {
		font-size: 40px;
		margin-right: 20px;
	}
	.data-line {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;
	}
	.ckey {
		font-size: 24px;
		line-height: 40px;
	}
	.cvalue {
		font-size: 16px;
		line-height: 24px;
		color: #999999;
	}
</style>
