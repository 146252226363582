<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.el-form-item {
		margin-bottom: 0px;
	}
	
	.t {
		width: 100px;
		text-align: right;
		margin-right: 10px;
	}
	
	.line {
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: 14px;
		line-height: 30px;
		height: 50px;
	}
	
	.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered {
		margin-left: 0;
	}
	
</style>

<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>赛事管理</el-breadcrumb-item>
			  <el-breadcrumb-item>报名管理</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top: 10px; background-color: white; padding: 10px; margin: 10px; border-radius: 5px;">
			<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-bottom: 10px;">
				<!-- <div style="height: 40px; line-height: 40px;">用户列表</div> -->
				<el-form :inline="true" class="demo-form-inline">
					<el-form-item label="状态">
						<el-select v-model="status" placeholder="全部">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="赛事ID">
						<el-input v-model="at_id" placeholder="赛事ID"></el-input>
					</el-form-item>
					<el-form-item label="关键字">
						<el-input v-model="keyword" placeholder="赛事名称/姓名/手机号"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="onSubmit">搜索查询</el-button>
						<el-button type="warning" @click="reset">重置</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" ref="myTable" border  style="width: 100%" 
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)" >
				
				
				
				<el-table-column prop="id" label="编号" width="80"  align="center">
				</el-table-column>
				
				<el-table-column prop="activity_name" label="赛事标题" width="300"  align="center">
				</el-table-column>
				
				<el-table-column prop="realname" label="姓名" width="120"  align="center">
				</el-table-column>

				
				<el-table-column prop="parent_phone" label="家长手机号" width="140"  align="center">
				</el-table-column>
				
				<el-table-column prop="add_time" label="报名日期" align="center" width="170">
				</el-table-column>
				
				<el-table-column prop="price" label="报名费用" align="center" width="140">
					<template slot-scope="scope">
						{{(scope.row.price/1).toFixed(2)}}
					</template>
				</el-table-column>
				
				<el-table-column prop="status" label="活动状态" align="center" width="140">
					<template slot-scope="scope">
						<el-button v-if="scope.row.status==0" size="mini">未支付</el-button>
						<el-button v-if="scope.row.status==1"  type="primary" size="mini">已支付</el-button>
					</template>
				</el-table-column>
	
				
				<el-table-column prop="status" label="支付状态"  align="center"  width="140">
					<template slot-scope="scope">
						<el-button v-if="scope.row.status==1"  type="primary" circle>付</el-button>
						<el-button v-if="scope.row.status==0" circle>否</el-button>
					</template>
				</el-table-column>
				
				
				<el-table-column prop="pay_time" label="支付时间" align="center" width="180">
				</el-table-column>
				
				<el-table-column fixed="right" label="操作" width="220" align="center">
					<template slot-scope="scope">
						<el-button @click="viewApply(scope.row)" type="primary" size="mini">报名信息</el-button>
					</template>
				</el-table-column>
				
			</el-table>
			<el-pagination background layout="prev, pager, next" :key="tableKey" :page-size="limit" @current-change="getData" :total="total" style="margin-top: 20px;"></el-pagination>
		</div>
		
		<el-dialog title="报名信息" v-if="winView" :visible.sync="winView" width="700px" @close="winViewClose">
			<div class="line" style="height: 140px;">
				<div class="t">照片：</div>
				<div style="display: flex; flex-direction: row; align-items: center;">
					<img style="width: 100px; height: 100px;" :src="apply_info.photo" @click="view_photo(apply_info.photo)" />
					<div style="display: flex; flex-direction: column; margin-left: 20px;">
						<el-upload class="upload-demo" action="https://chongteng.easy2.cn/Api/Upload/uploadImage" :show-file-list="false" :on-success="handleAvatarSuccess">
						  <el-button size="small" type="primary">重新上传</el-button>
						  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
						</el-upload>
					</div>
				</div>
			</div>
			<div class="line">
				<div class="t">报名编号：</div>
				<div>{{apply_info.id}}</div>
			</div>
			<div class="line">
				<div class="t">报名赛事：</div>
				<div>{{apply_info.activity_name}} （id:{{apply_info.at_id}}）</div>
			</div>
			<div class="line">
				<div class="t">分组：</div>
				<div><el-input v-model="apply_info.cate_name"></el-input></div>
			</div>
			<div class="line">
				<div class="t">曲目：</div>
				<div><el-input v-model="apply_info.music"></el-input></div>
			</div>
			<div class="line">
				<div class="t">姓名：</div>
				<div><el-input v-model="apply_info.realname"></el-input></div>
			</div>
			<div class="line">
				<div class="t">身份证：</div>
				<div><el-input v-model="apply_info.idcard"></el-input></div>
			</div>
			<div class="line">
				<div class="t">家长姓名：</div>
				<div><el-input v-model="apply_info.parent_name"></el-input></div>
			</div>
			<div class="line">
				<div class="t">手机号码：</div>
				<div><el-input v-model="apply_info.parent_phone"></el-input></div>
			</div>
			<div class="line">
				<div class="t">教师姓名：</div>
				<div><el-input v-model="apply_info.teacher_name"></el-input></div>
			</div>
			<div class="line">
				<div class="t">手机号码：</div>
				<div><el-input v-model="apply_info.teacher_phone"></el-input></div>
			</div>
			<div class="line">
				<div class="t">渠道来源：</div>
				<div><el-input v-model="apply_info.teacher_from"></el-input></div>
			</div>
			
			<div class="line"><div class="t">订单金额：</div><div>{{apply_info.price}}</div></div>
			<div class="line">
				<div class="t">支付状态：</div>
				<div>
					<el-button v-if="apply_info.pay_status==1" type="primary" size="mini">已支付</el-button>
					<el-button v-if="apply_info.pay_status==0" type="warning" size="mini">未支付</el-button>
				</div>
			</div>
			<div class="line"><div class="t">支付订单：</div><div>{{apply_info.order_sn}}</div></div>
			<div class="line"><div class="t">微信订单：</div><div>{{apply_info.trans_sn}}</div></div>
			<div class="line"><div class="t">下单时间：</div><div>{{apply_info.add_time}}</div></div>
			<div class="line"><div class="t">支付时间：</div><div>{{apply_info.pay_time}}</div></div>
			<div class="line">
				<div class="t"></div>
				<div><el-button type="danger" @click="change()" size="mini">确认修改</el-button></div>
			</div>
		</el-dialog>
		
	</div>
</template>

<script>

	
	export default {
		name:'List',
		components:{
	
		},
		data(){
			return {
				/* 操作订单控制参数 */
				winView:false,
				/* 搜索参数 */
				keyword:'',
				/* 表格参数 */
				tableKey:0,
				loading:false,
				limit:10,
				total:0,
				tableData: [],
				at_id:'',
				apply_info:{},
				options:[{
					value:'-1',
					label:'全部'
				},{
					value:'0',
					label:'未支付'
				},{
					value:'1',
					label:'抽签中'
				},{
					value:'2',
					label:'评比中'
				},{
					value:'88',
					label:'已完成'
				},{
					value:'99',
					label:'已取消'
				}],
				status: '-1'
			}
		},
		created() {
			this.getData();
		},
		methods:{
			handleAvatarSuccess(res){
				this.apply_info.photo = res.data.url;
			},
			change(){
				let that = this;
				this.$confirm('是否确认修改此订单?', '提示', {
					confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
				}).then(() => {
					that.changeOrder();
				}).catch(() => {
				});	
			},
			changeOrder(){
				const loading = this.$loading({
						lock: true,
						text: '修改中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				
				that.$api.post("/Activity/editApply", that.apply_info).then(res=>{
					if(res.code == 1)
					{
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					setTimeout(()=>{loading.close()}, 200);
				}).catch(res=>{
					loading.close();
				});
			},
			view_photo(_url){
				window.open(_url);
			},
			viewApply(_row){
				this.apply_info = _row;
				this.winView = true;
			},
			winViewClose(){
				
			},
			reset(){
				this.keyword = "";
				this.at_id = "";
				this.refresh();
			},
			actionOrderViewClose(){
				this.actionOrderInfo = {};
			},
			deleteOrder(row, index){
				let that = this;
				this.$confirm('是否确认删除此订单?', '提示', {
					confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
				}).then(() => {
					that.deleteOrderAction(row, index);
				}).catch(() => {
				});	
			},
			deleteOrderAction(row, index){
				const loading = this.$loading({
						lock: true,
						text: '结束中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				that.$api.post("/Order/deleteOrder", {order_id:row.order_id}).then(res=>{
					setTimeout(()=>{loading.close()}, 200);
					if(res.code == 1)
					{
						that.tableData.splice(index, 1);
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
			actionOrderFunc(e){
				this.actionOrderInfo = e;
				this.actionOrderTitle = e.room_name + "       " + e.store_name + " ";
				this.actionOrderView = true;
			},
			paywayChange(e){
				this.payway = e;
				this.getData();
			},
			storeChange(e){
				this.store_id = e;
				this.getData(1);
			},
			refresh(){
				this.getData();
			},
			getData(page=1){
				let that = this;
				that.loading = true;
				that.$api.post("/Activity/applyList", {page:page, keyword:that.keyword, status:that.status, at_id:that.at_id}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			
			onSubmit(){
				this.getData();
			}
		}
	}
</script>