<style>
	.com_title {
		border-bottom: solid 1px #dcdcdc;
		height: 50px;
		line-height: 50px;
		padding: 0 20px;
		margin-right: 1px;
		width: 100px;
		text-align: center;
		font-size: 14px;
	}
	
	.create_line {
		display: flex;
		flex-direction: row;
		line-height: 60px;
		height: 60px;
		align-items: center;
	}
	
	.key {
		width: 80px;
		text-align: right;
		font-size: 14px;
		margin-right: 20px;
	}
</style>
<template>
	<div id="body">
		<div style=" box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white;  display: flex;  flex-direction: row; justify-content: space-between; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<div style="display: flex; flex-direction: row; align-items: center; ">
				<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
				<el-breadcrumb separator-class="el-icon-arrow-right">
				  <el-breadcrumb-item>首页</el-breadcrumb-item>
				  <el-breadcrumb-item>公众号管理</el-breadcrumb-item>
				  <el-breadcrumb-item>菜单设置</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="body_content" >
			<div style="background-color: white; padding: 10px; border-radius: 10px; ">
				<el-button type="primary" @click="refresh">刷新</el-button>
				<el-button type="warning" @click="addTopMenu">添加一级菜单</el-button>
				<el-button type="danger" @click="syncMenu">同步菜单</el-button>
				<div style="margin-top: 20px;">
					<div style="display: flex; flex-direction: row; margin-top: 1px;">
						<div class="com_title" style="width: 10%; font-weight: bold;">一级菜单</div>
						<div class="com_title" style="width: 10%; font-weight: bold;">二级菜单</div>
						<div class="com_title" style="width: 10%; font-weight: bold;">类型</div>
						<div class="com_title" style="width: 20%; font-weight: bold;">参数</div>
						<div class="com_title" style="width: 10%; font-weight: bold;">排序</div>
						<div class="com_title" style="width: 30%; font-weight: bold;">操作</div>
					</div>
					<div  v-for="(top, tind) in menu_list" :key="tind">
						<div style="display: flex; flex-direction: row; margin-top: 1px;">
							<div class="com_title" style="width: 10%;">{{top.name}}</div>
							<div class="com_title" style="width: 10%;"></div>
							<div class="com_title" style="width: 10%;">{{top.type}}</div>
							<div class="com_title" style="width: 20%;">{{top.param}}</div>
							<div class="com_title" style="width: 10%;">{{top.sort}}</div>
							<div class="com_title" style="width: 30%;">
								<el-button size="small" type="primary" v-if="top.hasChild=='2'" @click="addChild(top)">添加二级</el-button>
								<el-button size="small" type="danger"  @click="editTop(top)">编辑</el-button>
								<el-button size="small" type="warning" @click="del(top)">删除</el-button>
							</div>
						</div>
						<div style="display: flex; flex-direction: row; margin-top: 1px;" v-for="(child, cind) in top.child" :key="cind">
							<div class="com_title" style="width: 10%;">|-</div>
							<div class="com_title" style="width: 10%;">{{child.name}}</div>
							<div class="com_title" style="width: 10%;">{{child.type}}</div>
							<div class="com_title" style="width: 20%;">{{child.param}}</div>
							<div class="com_title" style="width: 10%;">{{child.sort}}</div>
							<div class="com_title" style="width: 30%;">
								<el-button size="small" type="danger" @click="editChild(child)">编辑</el-button>
								<el-button size="small" type="warning" @click="del(child)">删除</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog title="一级菜单" :visible.sync="CreateTopView" width="700px" @close="CreateTopClose">
			<div>
				<div class="create_line">
					<div class="key">菜单名称</div>
					<el-input v-model="top.name" placeholder="菜单名称"></el-input>
				</div>
				<div class="create_line">
					<div class="key">存在二级</div>
					<el-radio v-model="top.hasChild" label="1">否</el-radio>
					  <el-radio v-model="top.hasChild" label="2">是</el-radio>
				</div>
				<div class="create_line" v-show="top.hasChild=='1'">
					<div class="key">菜单类型</div>
					<el-select v-model="top.type" placeholder="请选择菜单类型">
					  <el-option label="跳转小程序" value="miniprogram"></el-option>
					  <el-option label="打开网页" value="view"></el-option>
					</el-select>
				</div>
				<div class="create_line" v-show="top.hasChild=='1'">
					<div class="key">参数</div>
					<el-input v-model="top.param" placeholder="小程序填写小程序url,跳转网页填写完整网址"></el-input>
				</div>
				<div class="create_line">
					<div class="key">排序</div>
					<el-input type="number" v-model="top.sort" placeholder="数值从小到大,位置从左到右,从上到下"></el-input>
				</div>
				<div class="create_line" style="align-items: center; justify-content: center;">
					<el-button type="primary" @click="submitTop">确认</el-button>
				</div>
			</div>
		</el-dialog>
		
		<el-dialog title="二级菜单" :visible.sync="CreateChildView" width="700px" @close="CreateChildClose">
			<div>
				<div class="create_line">
					<div class="key">菜单名称</div>
					<el-input v-model="child.name" placeholder="菜单名称"></el-input>
				</div>
				<div class="create_line">
					<div class="key">跳转类型</div>
					<el-select v-model="child.type" placeholder="请选择菜单类型">
					  <el-option label="跳转小程序" value="miniprogram"></el-option>
					  <el-option label="打开网页" value="view"></el-option>
					</el-select>
				</div>
				<div class="create_line">
					<div class="key">跳转参数</div>
					<el-input v-model="child.param" placeholder="小程序填写小程序url,跳转网页填写完整网址"></el-input>
				</div>
				<div class="create_line">
					<div class="key">排序</div>
					<el-input type="number" v-model="child.sort" placeholder="数值从小到大,位置从左到右,从上到下"></el-input>
				</div>
				<div class="create_line" style="align-items: center; justify-content: center;">
					<el-button type="primary" @click="submitChild">确认</el-button>
				</div>
			</div>
		</el-dialog>
		
	</div>
</template>

<script>
	export default {
		name:'activityAdd',
		data(){
			return {
				top:{
					name:'',
					hasChild:'1',
					type:'',
					param:'',
					sort:0
				},
				child:{
					name:'',
					type:'',
					param:'',
					sort:0
				},
				CreateTopView: false,
				CreateChildView: false,
				menu_list:[],
				tmp_mid:0,
				action:'',
				pid:0
			}
		},
		mounted() {
			this.getData();
		},
		methods:{
			syncMenu(){
				const loading = this.$loading({
						lock: true,
						text: '同步中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;
				that.$api.post( "/Config/syncWechatMenu", {}).then(res=>{
					if(res.code == 200)
					{
						that.$message({
							 message: '同步成功',
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close()
				}).catch(res=>{
					loading.close();
				});
			},
			editChild(obj){
				let childObj = {
					mid : obj.mid,
					name : obj.name,
					type: obj.type,
					param: obj.param,
					sort: obj.sort
				};
				this.child = childObj;
				this.action = "edit";
				this.CreateChildView = true;
			},
			addChild(obj){
				console.log(obj);
				if(obj.child.length >= 5)
				{
					return that.$message({ message: '二级菜单最多5个，请删除后添加', type: 'warning' });
				}
				this.CreateChildView = true;
				this.pid = obj.mid;
			},
			editTop(obj){
				
				let has = obj.type.length == 0 ? '2' : '1';
				let topObj = {
					mid : obj.mid,
					name : obj.name,
					hasChild : has,
					type: obj.type,
					param: obj.param,
					sort: obj.sort
				};
				this.top = topObj;
				this.action = "edit";
				this.CreateTopView = true;
			},
			del(obj){
				if(obj.pid == 0 && obj.child.length!=0)
				{
					return this.$message({ message: '一级菜单下存在二级菜单，请先删除所属二级菜单！', type: 'warning' });
				}
				// 确认删除
				this.$confirm('是否确认删除此菜单?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
							lock: true,
							text: '请求中',
							spinner: 'el-icon-loading',
							background: 'rgba(0, 0, 0, 0.7)'
						});
					let that = this;
					that.$api.post( "/Config/delWechatMenu", {mid:obj.mid}).then(res=>{
						if(res.code == 200)
						{
							that.refresh();
							that.$message({
								 message: '删除成功',
								 type: 'success',
							});
						}else{
							that.$message({
								 message: res.msg,
								 type: 'warning',
							});
						}
						loading.close()
					}).catch(res=>{
						loading.close();
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			CreateChildClose(){
				this.child = {
					name:'',
					type:'',
					param:'',
					sort:0
				};
				this.action = '';
				this.pid = 0;
			},
			submitChild(){
				if(this.child.name.length == 0) return this.$message({ message: '请填写菜单名！', type: 'warning' });
				if(this.child.type.length == 0) return this.$message({ message: '请选择跳转类型！', type: 'warning' });
				if(this.child.param.length == 0) return this.$message({ message: '请填写跳转参数！', type: 'warning' });
				
				
				const loading = this.$loading({
						lock: true,
						text: '请求中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				let _url = "";
				if(this.action == "edit")
				{
					_url = "/Config/editWechatChildMenu";
				}else{
					this.child.pid = this.pid;
					_url = "/Config/addWechatChildMenu";
				}
				that.$api.post( _url, this.child).then(res=>{
					if(res.code == 200)
					{
						that.refresh();
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close()
				}).catch(res=>{
					loading.close();
				});
			},
			submitTop(){
				if(this.top.name.length == 0) return this.$message({ message: '请填写菜单名！', type: 'warning' });
				if(this.top.hasChild == '1')
				{
					if(this.top.type.length == 0) return this.$message({ message: '请选择跳转类型！', type: 'warning' });
					if(this.top.param.length == 0) return this.$message({ message: '请填写跳转参数！', type: 'warning' });
				}
				const loading = this.$loading({
						lock: true,
						text: '请求中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				let _url = "";
				if(this.action == "edit")
				{
					_url = "/Config/editWechatTopMenu";
				}else{
					_url = "/Config/addWechatTopMenu";
				}
				that.$api.post( _url, this.top).then(res=>{
					if(res.code == 200)
					{
						that.refresh();
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close()
				}).catch(res=>{
					loading.close();
				});
			},
			CreateTopClose(){
				this.top = {
					name:'',
					hasChild:'1',
					type:'',
					param:'',
					sort:0
				};
				this.action = '';
			},
			addTopMenu(){
				if(this.menu_list.length >= 3)
				{
					return this.$message({ message: '一级菜单最多3个，请删除后添加', type: 'warning' });
				}
				this.CreateTopView = true;
			},
			getData(){
				const loading = this.$loading({
						lock: true,
						text: '数据获取中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				that.$api.post("/Config/getWechatMenuConfig", {}).then(res=>{
					setTimeout(()=>{loading.close()}, 200);
					if(res.code == 200)
					{
						that.menu_list = res.data;
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
			refresh(){
				this.getData();
			},
			submit(){
				console.log("submit data", this.form);
				return;
				let that = this;
				if(that.form.appid == ""){
					return that.$message({ message: '请填写appid', type: 'warning' });
				}
				if(that.form.secret == ""){
					return that.$message({ message: '请填写secret', type: 'warning' });
				}
				if(that.form.message_token == ""){
					return that.$message({ message: '请填写message_token', type: 'warning' });
				}


				let loading = this.$loading({
						lock: true,
						text: '提交中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				
				that.$api.post("/Config/setWechatConfig", that.form ).then(res=>{
					if(res.code == 200 )
					{
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
						this.refresh();
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close()
				}).catch(err=>{
					loading.close()
				});
			}
		}
	}
</script>

<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	.body_content {
		padding: 10px;
	}

</style>