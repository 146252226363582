
<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	.body_content {
		padding: 10px;
	}

	
	.avatar-uploader .el-upload {
	  border: 1px dashed #d9d9d9;
	  border-radius: 6px;
	  cursor: pointer;
	  position: relative;
	  overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
	  border-color: #409EFF;
	}
	.avatar-uploader-icon {
	  font-size: 28px;
	  color: #8c939d;
	  width: 320px;
	  height: 160px;
	  line-height: 160px;
	  text-align: center;
	}
	.avatar {
	  width: 320px;
	  height: 160px;
	  display: block;
	}
	
	.title {
		color: #999999;
		font-size: 14px;
		line-height: 40px;
	}
</style>
<template>
	<div id="body">
		<div class="body_content" >
			<div style="background-color: white; padding: 10px; border-radius: 10px; ">
				<div style="display: flex; flex-direction: column; align-items: center;">
					<div class="title">封面 (宽高2:1)</div>
					<el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false" :on-success="CoverSuccess">
						<img v-if="coverUrl" :src="coverUrl" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</div>
				<div>
					<div class="title">资讯标题</div>
					<el-input placeholder="请输入标题" v-model="title"></el-input>
				</div>
				<div>
					<div class="title">资讯正文</div>
					<quill-editor
						v-model="content"
						ref="myQuillEditor"
						:options="editorOption"
						@blur="onEditorBlur($event)"
						@focus="onEditorFocus($event)"
						@change="onEditorChange($event)"
						@ready="onEditorReady($event)" style="height: 500px; margin-bottom: 50px;"> 
					</quill-editor>
				</div>
				<el-button style="margin-top:30px;" type="danger" @click="preview">编辑修改</el-button>
				<el-upload class="avatar-uploader1" :action="uploadUrl" name="file" :show-file-list="false" :on-success="uploadSuccess"></el-upload>
			</div>
		</div>
	</div>
</template>

<script>

/*
npm install quill
npm install quill-image-resize-module --save
npm install quill-image-drop-module --save
*/
import Quill from 'quill'  //引入编辑器
import ImageResize from 'quill-image-resize-module'
import { ImageDrop } from 'quill-image-drop-module'; // 图片拖动组件引用
Quill.register('modules/imageDrop', ImageDrop); // 注册
Quill.register('modules/imageResize', ImageResize);

var Align = Quill.import('attributors/style/align');
Align.whitelist = ['right', 'center', 'justify'];
Quill.register(Align, true);

const fontSize = ['12px', false, '16px', '18px', '20px', '24px', '28px', '32px', '36px'];
Quill.imports['attributors/style/size'].whitelist = fontSize;
Quill.register(Quill.imports['attributors/style/size']);

const fontFamily = ['SimSun', 'SimHei', 'KaiTi', 'FangSong'];
const Font = Quill.import('attributors/style/font');
Font.whitelist = fontFamily;
Quill.register(Font, true);

	
	
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";




// 导入自定义样式 一定要在原插件css下面引入  (看上面修改字体字号)
import "@/assets/css/quillEditor.css";
// 导入修改字体及字号 工具栏相关配置 设置工具栏中文提示（详细内容看下边）
import { titleConfig, toolbarOptions } from "@/assets/js/quillEditor";

export default {
	name:'editArticle',
	components: {
		quillEditor,
	},
	props:['aid'],
	data() {
    return {
		uploadUrl: 'https://chongteng.easy2.cn/Api/Upload/uploadImage',
		coverUrl:'',
		title:'',
		content: ``,
		
		editorOption: {
			modules: {
				imageDrop:true,
				imageResize:{
					displayStyles:{
						backgroundColor:"black",
						border:"none",
						color:"white"
					},
				modules:["Resize", "DisplaySize", "Toolbar"]
			},
			toolbar: {
				container: toolbarOptions, //工具栏相关配置
				handlers: {
					image: function (value) {
						if (value) {
							document.querySelector(".avatar-uploader1 input").click();
						} else {
							this.quill.format("image", false);
						}
					},
				},
			},
		},
		placeholder: "请输入正文",
		theme: "snow", //主题 snow：有工具栏的；bubble：只有文本域的
      },
    };
  },
  methods:{
  	getData(){
  		let loading = this.$loading({
  				lock: true,
  				text: '获取数据中',
  				spinner: 'el-icon-loading',
  				background: 'rgba(0, 0, 0, 0.7)'
  			});
  		let that = this;
  		that.$api.post("/Article/getArticle", {aid:this.aid} ).then(res=>{
  			if(res.code == 200 )
  			{
  				this.coverUrl = res.data.cover_url;
  				this.title = res.data.title;
  				this.content = res.data.content;
  				that.$message({
  					 message: res.msg,
  					 type: 'success',
  				});
  			}else{
  				that.$message({
  					 message: res.msg,
  					 type: 'warning',
  				});
  			}
  			loading.close()
  		}).catch(err=>{
  			loading.close()
  		});
  	},
	  CoverSuccess(res){
		  if(res.code == 200)
		  {
		  	this.coverUrl = res.data.url;
		  }else{
		  	return this.$message({
		  		 message: res.msg,
		  		 type: 'warning',
		  	});
		  }
	  },
	  preview(){
		let that = this;
		let field = new Object();
		field.cover_url = this.coverUrl;
		field.title = this.title;
		field.content = this.content;
		field.aid = this.aid;
		
		console.log("preView", field);
		if(field.cover_url.length == 0){
			return that.$message({ message: '请上传资讯封面图', type: 'warning' });
		}
		if(field.title.length == 0){
			return that.$message({ message: '请填写资讯标题', type: 'warning' });
		}
		if(field.content.length == 0){
			return that.$message({ message: '请填写资讯正文', type: 'warning' });
		}
		
		let loading = this.$loading({
				lock: true,
				text: '提交中',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
		
		that.$api.post("/Article/articleEdit", field ).then(res=>{
			if(res.code == 200 )
			{
				that.$message({
					 message: res.msg,
					 type: 'success',
				});
			}else{
				that.$message({
					 message: res.msg,
					 type: 'warning',
				});
			}
			loading.close()
		}).catch(err=>{
			loading.close()
		});
	  },
	  uploadSuccess(res) {
		console.log(res, "上传图片", this.$refs.myQuillEditor);
		// 获取富文本组件实例
		let quill = this.$refs.myQuillEditor.quill;
		
		console.log('quill', quill);
		// 如果上传成功
		if (res) {
		  // 获取光标所在位置
		  let length = quill.getSelection().index;
		  // 插入图片，res为服务器返回的图片链接地址
		  quill.insertEmbed(length, "image", res.data.url);
		  // 调整光标到最后
		  quill.setSelection(length + 1);
		} else {
		  // 提示信息，需引入Message
		  this.$message.error("图片插入失败！");
		}
	  },
	  refresh(){
		  this.title = '';
		  this.coverUrl = '';
		  this.content = '';
	  },
    // 失去焦点事件
    onEditorBlur(quill) {
        //console.log('editor blur!', quill)
    },
    // 获得焦点事件
    onEditorFocus(quill) {
        //console.log('editor focus!', quill)
    },
    // 准备富文本编辑器
    onEditorReady(quill) {
        //console.log('editor ready!', quill)
    },

    // 内容改变事件
    onEditorChange({ quill, html, text }) {
      //console.log("内容改变事件", quill, html, text);
      this.content = html;
    },
    
    //设置工具栏中文提示
    setTitleConfig() {
      for (const item of titleConfig) {
        const tip = document.querySelector(".quill-editor " + item.Choice);
        if (!tip) continue;
        tip.setAttribute("title", item.title);
        // 更改提示信息的内容
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setTitleConfig();
	  this.getData();
    });
  },
};
</script>
