import Vue from 'vue'
import Router from 'vue-router'
import MainFrame from '@/components/MainFrame'
import Login from '@/components/Login'

//数据面板
import Dashboard from '@/components/Dashboard/Dashboard'
//用户管理
import userList from '@/components/User/userList'


// 活动管理
import activityList from '@/components/Activity/activityList'
import activityAdd from '@/components/Activity/activityAdd'
import activityManager from '@/components/Activity/activityManager'

// 推文管理
import articleList from '@/components/Article/articleList'
import articleAdd from '@/components/Article/articleAdd'

// 公众号管理
import wechatSet from '@/components/Wechat/wechatSet'
import wechatMenu from '@/components/Wechat/wechatMenu'

// 小程序设置
import miniProgramSet from '@/components/miniProgram/miniProgramSet'
import wechatPay from '@/components/miniProgram/wechatPay'
import imagePlayer from '@/components/miniProgram/imagePlayer'

import App from '@/App.vue'

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    // 处理错误 跳转到重复路径上，忽略错误
    // console.error(err);
  });
};

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'App',
      component: App,
	  children:[
		  {
			path:'',
			component: MainFrame,
		  },
		  {
			name: 'Home',
			path: 'Home',
			component: MainFrame,
			children:[
				{
					path:'/Dashboard',
					name:'Dashboard',
					component:Dashboard
				},{
					path:'/Activity/activityList',
					name:'activityList',
					component:activityList
				},{
					path:'/Activity/activityAdd',
					name:'activityAdd',
					component:activityAdd
				},{
					path:'/Activity/activityManager',
					name:'activityManager',
					component:activityManager
				},{
					path:'/Article/articleList',
					name:'articleList',
					component:articleList
				},{
					path:'/Article/articleAdd',
					name:'articleAdd',
					component:articleAdd
				},{
					path:'/Wechat/wechatSet',
					name:'wechatSet',
					component:wechatSet
				},{
					path:'/Wechat/wechatMenu',
					name:'wechatMenu',
					component:wechatMenu
				},{
					path:'/User/userList',
					name:'userList',
					component:userList
				},{
					path:'/miniProgram/miniProgramSet',
					name:'miniProgramSet',
					component:miniProgramSet
				},{
					path:'/miniProgram/wechatPay',
					name:'wechatPay',
					component:wechatPay
				},{
					path:'/miniProgram/imagePlayer',
					name:'imagePlayer',
					component:imagePlayer
				}
			]
		  }
	  ]
    },{
      path: '/Login',
      name: 'Login',
      component: Login
    }
  ]
})
