<template>
	<div>
		<div style="background-color: white; padding: 10px; border-radius: 10px; ">
			<el-form ref="form"  label-width="180px" style="width: 700px;" >
				<el-form-item label="赛事封面">
					<el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false" :on-success="ActivitySuccess">
						<img v-if="activityData.active_img" :src="activityData.active_img" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="赛事标题">
					<el-input placeholder="赛事标题" v-model="activityData.active_title"></el-input>
				</el-form-item>
				<el-form-item label="子标题">
					<el-input placeholder="子标题" v-model="activityData.active_name"></el-input>
				</el-form-item>
				<el-form-item label="赛事简介">
					<el-input placeholder="赛事简介" v-model="activityData.active_desc"></el-input>
				</el-form-item>
				<el-form-item label="报名费用">
					<el-input placeholder="报名费用" type="number" v-model="activityData.price"></el-input>
				</el-form-item>
				<el-form-item label="报名时间">
					<el-date-picker v-model="activityData.start_time" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="截止时间">
					<el-date-picker v-model="activityData.ended_time" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="赛事介绍">
					<div v-for="(item,index) in activityData.desc_imgs" style="display: inline-block; position: relative;">
						<img class="up_img"  :src="item" @click="preview(item)" />
						<i class="el-icon-close up_img_close" @click="close_img(item)" ></i>
					</div>
					
					
					<el-upload class="avatar-uploader" style="display: inline;" :action="uploadUrl" :show-file-list="false" :on-success="ActivityDescSuccess">
						<i  class="el-icon-plus avatar-uploader-icon1"></i>
					</el-upload>
					
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submit">确认编辑</el-button>
				</el-form-item>
				
				<el-dialog :visible.sync="dialogVisible">
					<img width="100%" :src="dialogImageUrl" alt="">
				</el-dialog>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		name:'editActivity',
		props:['at_id'],
		data(){
			return {
				uploadUrl:'https://chongteng.easy2.cn/Api/Upload/uploadImage',
				dialogImageUrl: '',
				dialogVisible: false,
				activityData:{}
			}
		},
		created() {
		},
		mounted() {
			this.getData();
		},
		methods:{
			getData(){
				let loading = this.$loading({
						lock: true,
						text: '获取数据中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;
				that.$api.post("/Activity/getActivity", {at_id:this.at_id} ).then(res=>{
					if(res.code == 200 )
					{
						that.activityData = res.data;
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
						this.refresh();
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close()
				}).catch(err=>{
					loading.close()
				});
			},
			close_img(_url){
				let new_arr = [];
				this.activityData.desc_imgs.forEach((item,index)=>{
					if(item != _url)
					{
						new_arr.push(item);
					}
				});
				this.activityData.desc_imgs = new_arr;
			},
			preview(_url){
				this.dialogVisible = true;
				this.dialogImageUrl = _url;
			},
			ActivityDescSuccess(res, file) {
				if(res.code == 200)
				{
					this.activityData.desc_imgs.push(res.data.url);
				}else{
					return this.$message({
						 message: res.msg,
						 type: 'warning',
					});
				}
			},
			ActivitySuccess(res, file) {
				if(res.code == 200)
				{
					this.activityData.active_img = res.data.url;
				}else{
					this.activityData.active_img = '';
					return this.$message({
						 message: res.msg,
						 type: 'warning',
					});
				}
			},
			onUploadSuccess(response, file, fileList){
				fileList.forEach((item,index)=>{
					console.log(item, index);
				});
			},
			submit(){
				console.log(this.activityData);
				let that = this;
				
				if(that.activityData.active_img == ""){
					return that.$message({ message: '请上传赛事封面', type: 'warning' });
				}
				if(that.activityData.active_title == ""){
					return that.$message({ message: '请输入赛事标题', type: 'warning' });
				}
				if(that.activityData.active_name == ""){
					return that.$message({ message: '请输入赛事子标题', type: 'warning' });
				}
				if(that.activityData.active_desc == ""){
					return that.$message({ message: '请输入赛事简述', type: 'warning' });
				}
				if(isNaN(that.activityData.price) || that.activityData.price <= 0  ){
					return that.$message({ message: '请输入正确报名费用', type: 'warning' });
				}
				if(that.activityData.start_time == ""){
					return that.$message({ message: '请选择报名时间', type: 'warning' });
				}
				if(that.activityData.ended_time == ""){
					return that.$message({ message: '请输入截止时间', type: 'warning' });
				}
				if(that.activityData.desc_imgs.length == 0){
					return that.$message({ message: '请至少上传一张赛事介绍图片', type: 'warning' });
				}
			
				let loading = this.$loading({
						lock: true,
						text: '提交中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				
				that.$api.post("/Activity/activityEdit", that.activityData ).then(res=>{
					if(res.code == 200 )
					{
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
						this.refresh();
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close()
				}).catch(err=>{
					loading.close()
				});
			}
		},
	}
</script>


<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	.body_content {
		padding: 10px;
	}

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 360px;
    height: 180px;
    line-height: 180px;
    text-align: center;
  }
  .avatar-uploader-icon1 {
    font-size: 28px;
    color: #8c939d;
    width: 180px;
    height: 180px;
    line-height: 180px;
    text-align: center;
  }
  .avatar {
    width: 360px;
    height: 180px;
    display: block;
  }
  .up_img {
	  width: 178px; height: 178px; border-radius: 5px; margin-right: 5px;
	  border:dashed 1px #dddddd;
  }
  
  .up_img:hover {
	  cursor: move;
	  border:dashed 1px #409EFF;
	  width: 178px;
	  height: 178px;
  }
  
  .up_img_close {
	  color:black; width:20px; height: 20px; border-radius: 10px; background-color: #CCCCCC; text-align: center; line-height: 20px; position: absolute; right: 15px; top: 10px;
  }
  
  .up_img_close:hover {
	  background-color: #409EFF;
	  color: white;
	  cursor: pointer;
  }
</style>